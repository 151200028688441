.archetype-container {
    display: flex;
    flex-direction: column;
    border-radius: 7px;
    overflow: hidden;
    margin-bottom: 20px;
}

.archetype-heading-bar {
    display: flex;
    align-items: center;
    background-color: var(--p700);
    padding: 30px;
    border-radius: 7px;
    color: var(--white);
    font-size: 1.8rem;
}

.archetype-image {
    width: 200px;
    height: auto;
    margin-right: 20px;
}

svg.archetype-image path {
    fill: var(--p200);
}

.archetype-text-content {
    flex: 1;
}

.archetype-name, .archetype-short-description, .archetype-signature-insight {
    margin: 18px;
}

.archetype-short-description {
    font-size: 1.4rem;
    font-weight: var(--semi-bold);
    color: var(--g100);
}

.archetype-signature-insight {
    font-size: 1.2rem;
}

.archetype-long-description {
    padding: 20px;
    font-size: 1.1rem;
    margin: 20px 5% 0 5%;
}

.archetype-long-description h3 {
    font-size: 1.5rem;
}

.archetype-styles-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 30px;
    padding: 20px;
    border-radius: 0 0 7px 7px;
}

.archetype-style-box {
    flex: 1;
    min-width: 250px;
    background-color: var(--white);
    padding: 10px 30px;
    border-radius: 7px;
    text-align: center;
}

.archetype-style-box h4 {
    font-size: 1.2rem;
    color: var(--p500);
}

@media (max-width: 768px) {
    .archetype-heading-bar {
        flex-direction: column;
    }

    .archetype-styles-container {
        flex-direction: column;
    }

    .archetype-style-box {
        min-width: auto;
        width: 100%;
    }
}
