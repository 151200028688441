@media print {
    .pagebreak {
        page-break-before: always;
        margin-top: 80px;
    }

    /* .page-title-container {
        background-color: var(--p500) !important;
        margin-bottom: 12px !important;
        color: var(--white) !important;
    } */

    .page-title-container {
        margin-top: -64px !important;
    }

    /* .app-header {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    } */

    div.tabs, .detailed-settings-link,
    .archive-column, .dropdowns-container, .sidebar,
    .search-bar, .search-bar-container, .app-header,
    .linked-positions-container {
        display: none !important;
    }

    /* .archetype-heading-bar, .page-title-container  {
        -webkit-print-color-adjust:exact !important;
        print-color-adjust:exact !important;
    } */

    .profile-section, 
    .tab-content, .archetype-long-description {
        padding: 0 !important;
    }

    .export-icon {
        display: none !important;
    }
}