.interview-guide {
    /*background-color: var(--g100);*/
    border-radius: 8px;
    padding: 20px;
    margin-top: 20px;
}

.interview-guide h2 {
    color: var(--black);
    border-bottom: 2px solid var(--primary);
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.guide-item {
    background-color: var(--white);
    /*border: 1px solid var(--g100);*/
    border-radius: 4px;
    padding: 15px;
    margin-bottom: 20px;
}

.guide-item h3 {
    color: var(--primary);
    margin-bottom: 10px;
}

.guide-item h4 {
    color: var(--g700);
    margin-top: 15px;
    margin-bottom: 10px;
    font-weight: 700;
}

.guide-item ol {
    padding-left: 20px;
}

.guide-item li {
    margin-bottom: 10px;
}

.guide-scenario {
    font-weight: var(--semi-bold);
}

@media screen and (max-width: 768px) {
    .guide-item li {
        font-size: 0.9rem;
    }
}