.dashboard-profile-bar {
    display: flex;
    align-items: center;
    gap: 16px;
}

.dashboard-profile-avatar {
    height: 140px;
    width: 140px;
    border-radius: 50%;
}

.dashboard-profile-greeting {
    color: var(--p500);
}

.dashboard-profile-description {
    margin: 10px 0;
}

.dashboard-tables-container {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    margin-top: 40px;
}

.dashboard-tables-container h2 {
    margin: 0;
}

.full-width {
    flex: 0 0 100%;
}

.width-65 {
    flex: 0 0 calc(65% - 15px);
}

.width-35 {
    flex: 0 0 calc(35% - 15px);
}

.recent-assessments-table, .recent-targets-table, .recent-folders {
    margin-bottom: 30px;
}

.recent-assessments-table h2, .recent-targets-table h2, .recent-folders h2 {
    margin: 0 0 15px 0;
}

.table-group-icon {
    width: 20px;
    height: 20px;
}

.view-all-button {
    background-color: var(--p500);
    text-wrap: nowrap;
}

.copied-message {
    margin-left: 10px;
    color: var(--g500);
    font-size: 14px;
}

.align-right {
    text-align: right;
}

@media (max-width: 768px) {
    .dashboard-profile-description {
        font-size: 0.9rem;
    }

    .dashboard-tables-container {
        flex-direction: column;
    }

    .width-65, .width-35 {
        flex: 0 0 100%;
    }

    .recent-assessments-table, .recent-targets-table {
        flex: 0 0 100%;
    }

    .recent-targets-table {
        padding-top: 20px;
    }

    .recent-assessments-email {
        display: none;
    }

    .recent-assessments-chart {
        width: 60%;
    }
}
