/* Chart Container */
.target-header,
.group-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 60%;
    margin-bottom: 8px;
}

.group-header {
    max-width: 100%;
}

.group-header h2{
    margin-bottom: 0px;
}
.target-header {
    margin-bottom: 12px;
}
.target-header h2 {
    display: flex;
    align-items: center;
    margin-bottom: 0px;
}

.target-header .edit-target-chart-conatiner {
    display: flex;
    align-items: center;
    gap: 8px;
}

.target-profile-avatar {
    width: 36px;
    height: 36px;
    margin-right: 8px;
    border-radius: 50%;
}

.workstyles-chart-container,
.resonance-chart-container,
.target-chart-container, 
.workstyles-chart-heading {
    max-width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    margin-bottom: 30px;
    position: relative;
}

.target-chart-container {
    display: block;
}

.workstyles-chart-container {
    margin-bottom: 16px;
}

.workstyles-chart-container,
.resonance-chart-container {
    transition: max-width 0.3s ease;
    filter: drop-shadow(var(--filter-large));
}

.resonance-chart-container {
    margin-top: -00px;
}

/* ISCP Chart */
.chart-background {
    fill: var(--white);
}

.midpoint-line {
    stroke: var(--g700);
}

.bottom-rectangle {
    fill: var(--p900);
}

.border-rectangle {
    stroke: var(--g700);
}

.sigma-line {
    stroke: var(--g250);
}

.chart-label {
    font-size: 15px;
    font-family: var(--text);
    font-weight: var(--normal);
    fill: var(--black);
}

.workstyles-svg,
.resonance-svg {
    width: 100%;
    max-width: 1119px;
    height: auto;
    margin-top: 00px;
}

.iscp-circles,
.standalone-circles {
    cursor: pointer;
}

.workstyles-text,
.standalone-text {
    font-family: var(--headings);
    font-size: 24px;
    font-weight: var(--semi-bold);
    text-anchor: middle;
    fill: white;
    cursor: pointer;
}

.standalone-text {
    font-size: 22px;
}

/* Resonance Chart */
.trait-line-background {
    stroke: var(--g100);
}

.trait-line-foreground {
    stroke: var(--g300);
}

.trait-circle-er { fill: var(--er-color); }
.trait-circle-ps { fill: var(--ps-color); }
.trait-circle-si { fill: var(--si-color); }
.trait-circle-ab { fill: var(--ab-color); }
.trait-circle-in { fill: var(--in-color); }

.tooltip-background-er { fill: var(--er-color); }
.tooltip-background-ps { fill: var(--ps-color); }
.tooltip-background-si { fill: var(--si-color); }
.tooltip-background-ab { fill: var(--ab-color); }
.tooltip-background-in { fill: var(--in-color); }

.tooltip-text {
    fill: var(--white);
    font-size: 14px;
}

/* Quadrants Chart */
.quadrant-chart-container {
    display: flex;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    gap: 5%;
}

.quadrant-chart-wrapper {
    width: 100%;
    max-width: 555px;
    margin-top: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.quadrants-svg {
    width: 100%;
    height: auto;
    max-height: calc(100vh - 100px); /* Account for header and controls */
    aspect-ratio: 1 / 1;
}

.q-chart-axis-label,
.q-chart-text {
    font-size: 14px;
}

.q-chart-title {
    font-weight: bold;
    font-size: 15px;
}

.q-chart-axis-label {
    font-weight: var(--bold);
}

/* Quadrant Table */
.quadrant-table-wrapper {
    width: 100%;
    max-height: calc(100vh - 100px);
    overflow-y: auto;
}

.quadrant-table {
    width: 100%;
    border-collapse: collapse;
}

.quadrant-table tr {
    cursor: pointer;
}

.quadrant-table-row {
    max-height: 40px;
    height: 40px;
}

.quadrant-table-row td {
    padding: 4px 8px;
    vertical-align: middle;
    white-space: nowrap;
}

.quadrant-table .table-profile-icon {
    width: 24px;
    height: 24px;
}

.quadrant-table .workstyles-mini-chart {
    height: 32px;
    vertical-align: middle;
}

.quadrant-table .iscp-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
}

.quadrant-chart-icon {
    width: 30px;
    height: 30px;
    cursor: pointer;
}

/* Hover state */
.quadrant-chart-icon.hovered {
    --profile-bg: var(--p700);
}

/* Selected state */
.quadrant-chart-icon.selected {
    --profile-bg: var(--p900);
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
}

/* Ensure selected icon container is always on top */
g.selected-icon {
    isolation: isolate;
    z-index: 10;
}

/* Multiple selected icons should stack nicely */
g.selected-icon + g.selected-icon {
    z-index: 11;
}

.quadrant-row-hover td {
    background-color: var(--g100) !important;
}

.quadrant-row-selected td {
    background-color: var(--p100) !important;
}

/* When multiple rows are selected */
.quadrant-row-selected + .quadrant-row-selected td {
    border-top: none;
}

/* Keep hover effect consistent with single-select behavior */
.quadrant-row-selected:hover td {
    background-color: var(--g100) !important;
}

.tooltip {
    background: white;
    padding: 4px 8px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid var(--g200);
    pointer-events: none;
    z-index: 100;
}

.quadrant-bg {
    fill: var(--white);
    stroke: none;
}

.quadrant-circle {
    fill: none;
    stroke: var(--g700);
    stroke-width: 1;
}

.quadrant-axis {
    stroke: var(--g200);
    stroke-width: 1;
    stroke-dasharray: 1, 1;
}

.q-chart-title,
.q-chart-text,
.q-chart-axis-label {
    fill: var(--black);
}

.q-profile-icon {
    width: 30px;
    height: 30px;
}

.axis-label {
    fill: var(--g700);
    font-size: 14px;
}

.axis-label-end {
    fill: var(--g500);
    font-size: 12px;
}

table.quadrant-table {
    margin-top: 6px;
}

.dark-mode .q-profile-icon {
    --profile-fg: var(--black);
}

/* Dark Mode */
.dark-mode .quadrant-bg {
    fill: var(--black);
}

.dark-mode .quadrant-circle {
    stroke: var(--g200);
}

.dark-mode .quadrant-axis {
    stroke: var(--g500);
}

/* Quadrant Chart Controls */
.chart-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.view-controls {
    display: flex;
    gap: 12px;
    align-items: center;
}

.view-mode-select {
    padding: 0.5rem;
    border-radius: 4px;
    border: 1px solid var(--g200);
}

.expand-button {
    padding: 0.5rem;
    border-radius: 4px;
    background: var(--p500);
    color: white;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    transition: background-color 0.2s ease;
}

.expand-button:hover {
    background: var(--p700);
}

.expand-button svg {
    display: block;
}

.cluster-tooltip {
    background: white;
    padding: 8px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    border: 1px solid var(--g200);
    font-size: 12px;
    max-width: 200px;
    word-wrap: break-word;
}

.quadrant-chart-container {
    display: flex;
    gap: 2rem;
    transition: all 0.3s ease;
}

.quadrant-chart-container.expanded .quadrant-chart-wrapper {
    width: 100%;
    max-width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.quadrant-chart-container.expanded .quadrants-svg {
    flex: 1;
    width: auto;
    height: auto;
    max-height: calc(100vh - 100px);
    margin: 0 auto;
}

.clear-selections-button {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    border-radius: 16px;
    border: none;
    background-color: var(--g150);
    color: var(--g700);
    font-size: 13px;
    cursor: pointer;
    font-family: var(--text);
    font-weight: var(--thin);
}

.clear-selections-button:hover {
    background-color: var(--p100);
}

.clear-selections-button svg {
    margin-left: 3px;
    cursor: pointer;
    height: 12px;
    order: 2; /* Move X icon to the right */
}

.rating-dot {
    transition: r 0.2s ease, fill 0.2s ease;
}

.rating-dot.hovered {
    r: 8;
}

.table-rating-dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 8px;
    vertical-align: middle;
}

.rating-legend {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 4px 8px;
    background: var(--white);
    border: 1px solid var(--g200);
    border-radius: 4px;
}

.rating-legend .rating-dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: inline-block;
}

.quadrant-dot {
    transition: r 0.2s ease-in-out;
}

.quadrant-dot.hovered {
    r: 8;
}

.workstyles-chart-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}

.workstyles-chart-heading .visualization-toggle-icon {
    display: none;
    margin-left: auto;
    cursor: pointer;
    transition: transform 0.2s ease;
}

.workstyles-chart-heading.core-identity .visualization-toggle-icon {
    display: block;
}

.workstyles-chart-heading .visualization-toggle-icon:hover {
    transform: scale(1.1);
}

.visualization-toggle-icon {
    fill: var(--g500);
}

.density-score-text {
    fill: var(--g600);
}
