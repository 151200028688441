.narrow-table {
    max-width: 400px;
}

.entities-table tr {
    height: 40px;
}

.data-analysis-container .button {
    margin-left: 20px;
}

.data-analysis-container h3 {
    padding-top: 10px;
}

.data-analysis-container h4 {
    padding: 0;
    margin: 0;
}

.data-analysis-container table {
    padding-bottom: 30px;
}

.data-analysis-controls {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.data-analysis-controls select {
    margin-right: 1rem;
}

.data-analysis-controls .button {
    display: flex;
    align-items: center;
}

.data-analysis-controls .button .material-icons {
    margin-right: 0.5rem;
}

.data-analysis-table tr {
    height: auto;
}

.data-analysis-table td {
    padding: 6px;
}

.adjective-management-table {
    width: 100%;
    border-collapse: collapse;
}

.adjective-management-table th, .adjective-management-table td {
    border: 1px solid var(--g100);
    padding: 4px; /* Reduced padding to lower the row height */
    font-size: 12px; /* Slightly smaller font size */
}

.adjective-management-table th {
    background-color: transparent;
    text-align: left;
    color: var(--g500);
    height: 30px;
}

.adjective-management-table tr {
    height: auto; /* Override global row height */
}

.adjective-management-table td input[type="text"],
.adjective-management-table td select,
.adjective-management-table th select {
    padding: 3px 6px; /* Reduced padding for inputs and selects */
    font-size: 12px; /* Match font size with table cells */
    border-radius: 4px; /* Slightly smaller border radius */
    border: 1px solid var(--g200);
    color: var(--p500);
    background-color: var(--white);
}

.adjective-management-table th select {
    margin-left: 20px;
    border: 1px solid var(--p100);
    color: var(--g300);
    background-color: var(--g100);
}

.adjective-management-table td input[type="text"] {
    border: none;
    color: var(--g500);
}

.adjective-management-table td input[type="text"]:focus,
.adjective-management-table td select:focus {
    border: 1px solid var(--g100);
    color: var(--p500);
}

.adjective-management-table select[data-phase="bronze"] {
    background-color: #cd7f32; /* Bronze color */
    color: #fff;
}

.adjective-management-table select[data-phase="silver"] {
    background-color: #c0c0c0; /* Silver color */
    color: #000;
}

.adjective-management-table select[data-phase="gold"] {
    background-color: #ffd700; /* Gold color */
    color: #000;
}

.adjective-management-table select[data-phase=""] {
    background-color: #fff; /* Default color */
    color: #000;
}

.phase-filter-button, .association-filter-button {
    margin-left: 20px;
    color: var(--g500);
    padding: 5px 10px;
    font-size: 12px;
}

.phase-dropdown, .association-dropdown {
    position: absolute;
    top: 100%;
    left: 40px;
    background-color: white;
    border: 1px solid #ccc;
    padding: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    min-width: 130px;
}

.phase-dropdown label, .association-dropdown label {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    cursor: pointer;
}

.phase-dropdown input, .association-dropdown input {
    margin-right: 5px;
}

table th {
    padding-top: 15px;
    height: 40px;
}

.data-table-header td {
    font-weight: var(--bold);
    background-color: var(--p300);
    color: var(--white);
}

.assessment-actions {
    max-width: 880px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    margin-top:28px;
}

.count-icon {
    margin-left: 4px;
    vertical-align: middle;
    color: var(--p500);
}

/* Profile Management */
.profile-select {
    width: auto;
    padding: 10px;
    font-size: 16px;
    flex-grow: 1;
    margin-right: 10px;
}

.profile-actions {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.new-profile-button {
    padding: 10px 20px;
    cursor: pointer;
}

.profile-editor {
    background-color: var(--white);
    padding: 20px;
    border-radius: 5px;
}

.profile-edit-button {
    margin-right: 10px;
    margin-bottom: 20px;
}

.profile-field {
    margin-bottom: 20px;
}

.profile-field h2 {
    margin-bottom: 10px;
    font-size: 18px;
    text-transform: capitalize;
}

.text-input {
    width: 50%;
    padding: 8px;
    font-size: 14px;
}

.textarea-input {
    width: 75%;
    padding: 8px;
    font-size: 14px;
    min-height: 100px; /* This ensures at least 4 lines */
}

.view-mode ul {
    padding-left: 20px;
}

.view-mode p, .view-mode li {
    font-size: 14px;
    line-height: 1.6;
}

.advisor-chip {
    display: inline-flex;
    flex-direction: row;
    margin: 1px;
    overflow: hidden;
    background-color: var(--p300);
    flex-shrink: 0;
    align-items: center;
    user-select: none;
    border-radius: 50%;
    justify-content: center;
    width: 20px;
    color: #fff;
    height: 20px;
    font-size: 10px;
}

.entity-details-container {
  width: 100%;
  max-width: 800px;
  /* margin: 0 auto; */
}

.entity-form {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}

.entity-form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.entity-form-group label {
  font-size: 0.9rem;
  color: var(--g500);
  margin-bottom: 0.25rem;
}

.entity-input-container {
  width: 100%;
}

.entity-form-input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid var(--p500);
  border-radius: 4px;
}

/* Grouped fields */
.entity-group,
.entity-address-group,
.entity-name-group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.entity-date-group,
.entity-group-three {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}

/* Adjective Selection */
.adjective-count {
    color: var(--g500);
}

.date-comparison-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
}

.date-comparison-select {
    display: flex;
    align-items: center;
    gap: 10px;
}

.date-comparison-legend {
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-size: 14px;
    text-align: right;
}

.date-comparison-legend .removed {
    font-weight: 500;
    text-decoration: line-through;
}

.date-comparison-legend .added {
    font-weight: 500;
    font-style: italic;
}

/* Responsive design */
@media (max-width: 768px) {
  .entity-form {
    grid-template-columns: 1fr;
  }

  .entity-group,
  .entity-address-group,
  .entity-name-group,
  .entity-date-group,
  .entity-group-three {
    grid-template-columns: 1fr;
  }
}

.form-section {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--g200);
}

.form-section h3 {
  color: var(--p500);
  margin-bottom: 1rem;
}

/* Assessment Stats Styling */
.assessment-stats {
  padding: 0 1rem 0 1rem;
  margin-bottom: 1.4rem;
}

.stats-row {
  margin-bottom: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.5rem;
}

.stat-circle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  color: white;
  font-size: 12px;
  font-weight: 300;
  margin-right: 4px;
}

.assessment-stats-table th {
  padding: 4px;
  font-weight: normal;
  vertical-align: middle;
}

.assessment-stats-table tr {
  height: 36px;
}

.assessment-stats-table th .stat-circle {
  margin: 0 auto;
}
