/* Dark Mode Button */
.dark-mode-toggle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: var(--p700);
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
    outline: none;
    padding: 0;
    margin: 0 16px;
}

.dark-mode-toggle:hover {
    background-color: var(--p700);
}

.dark-mode-toggle svg {
    width: 20px;
    height: 20px;
    color: var(--white);
    transition: color 0.3s ease;
}

/* Button Styles for when dark mode is active*/
.dark-mode .dark-mode-toggle {
    background-color: var(--g200);
}

.dark-mode .dark-mode-toggle:hover {
    background-color: var(--white);
}

.dark-mode .dark-mode-toggle svg {
    color: var(--black);
}

.dark-mode-toggle svg {
    transition: transform 0.3s ease;
}

.dark-mode-toggle:active svg {
    transform: scale(0.9);
}

/* Dark Mode Global CSS */
body.dark-mode {
    background-color: var(--g800);
    color: var(--g250);
}

body.dark-mode {
    --profile-fg: var(--black);
    --logo: var(--black);
}

.dark-mode a {
    color: var(--p300);
}

.dark-mode table tbody td {
    background-color: var(--black);
    color: var(--g200);
}

.dark-mode table tbody tr {
    background-color: var(--black);
}

.dark-mode input,
.dark-mode textarea,
.dark-mode select {
    background-color: var(--black);
    color: var(--p100);
}

.dark-mode .search-bar-dropdown {
    background-color: var(--black);
}

.dark-mode .search-bar-option {
    color: var(--p200);
}

/* Buttons */
.dark-mode .button {
    color: var(--black);
}

.dark-mode .icon-white {
    fill: var(--black);
}

.dark-mode .button-cancel,
.dark-mode .copy-link-button, 
.dark-mode .share-model-button,
.dark-mode .button-grey {
    background-color: var(--g700);
    color: var(--g200);
}

.dark-mode .filter-button {
    background-color: var(--black);
    color: var(--g200);
}

.dark-mode .actions-dropdown, .dark-mode .archive-dropdown {
    background-color: var(--black);
    border: 1px solid var(--g500);
}

.dark-mode .actions-dropdown .action {
    color: var(--g200);
}

.dark-mode .filter-chip {
    background-color: var(--g700);
}

.dark-mode .layout-job-title {
    color: var(--g200);
}

/* Navigation Links */
.dark-mode .navigation-links li a,
.dark-mode .sidebar-navigation li a,
.dark-mode .sidebar-navigation li svg,
.dark-mode .sidebar-navigation li a.active,
.dark-mode .sidebar-navigation li a.active svg,
.dark-mode .sidebar-navigation li a:hover,
.dark-mode .sidebar-navigation li a:hover svg,
.dark-mode .magnifying-glass-icon svg,
.dark-mode .mobile-bottom-icons a,
.dark-mode .mobile-bottom-icons button {
    color: var(--black);
}

.dark-mode .navigation-links li a,
.dark-mode .sidebar-navigation li a,
.dark-mode .sidebar-navigation li svg,
.dark-mode .mobile-bottom-icons a,
.dark-mode .magnifying-glass-icon svg,
.dark-mode .mobile-bottom-icons a,
.dark-mode .mobile-bottom-icons button,
.dark-mode .mobile-bottom-icons a.inactive,
.dark-mode .mobile-bottom-icons button.inactive {
    opacity: 0.8;
}

/* Statistics Boxes */
.dark-mode .stat-box {
    background-color: var(--black);
    color: var(--g200);
}

/* ISCP Chart */
.dark-mode .chart-background {
    fill: var(--black);
}

.dark-mode .midpoint-line {
    stroke: var(--g200);
}

.dark-mode .border-rectangle {
    stroke: var(--g300);
}

.dark-mode .sigma-line {
    stroke: var(--g700);
}

/* Target Overlay */
.dark-mode .target-overlay {
    background-color: rgba(0, 0, 0, 0.8);
}

/* Target Survey */
.dark-mode .likert-card:hover {
    background-color: var(--g700);
    /* color: var(--black); */
}

.dark-mode .progress-bar {
    background-color: var(--g300);
}

/* Resonance Chart */
.dark-mode .trait-line-background {
    stroke: var(--g700);
}

.dark-mode .trait-line-foreground {
    stroke: var(--g400);
}

.dark-mode .chart-label {
    fill: var(--g100);
}

/* Density Score */
.dark-mode .density-score-text {
    fill: var(--g200);
}

/* Interview Guide */
.dark-mode .guide-item {
    background-color: var(--black);
    color: var(--g100);
}

.dark-mode .interview-guide h2 {
    color: var(--white);
}

.dark-mode .guide-item h4 {
    color: var(--g100);
}

/* Other */
.dark-mode table tbody tr.hovered-row > td {
    background-color: var(--g700);
}

.dark-mode .position-toggle-button {
    background-color: var(--g700);
    color: var(--g200);
}

.dark-mode table tbody tr.selected-row td {
    background-color: var(--p900);
}

.dark-mode .position-toggle-button.active {
    background-color: var(--p500);
    color: var(--black);
}

.dark-mode .thumbs-icon path {
    fill: var(--g500);
}

.dark-mode .profile-section {
    background-color: var(--black);
}

.dark-mode .form-group label {
    color: var(--g200);
}

.dark-mode input.form-input,
.dark-mode textarea.form-input,
.dark-mode select.form-input {
    border-color: var(--g500);
}

.dark-mode .search-input, .dark-mode .filter-checkbox-list {
    border: 1px solid var(--g500);
}

.dark-mode .search-bar-option.highlighted {
    background-color: var(--p500); 
    color: var(--white);
}

/* Modals & Dropdowns */
.dark-mode .header-profile-dropdown .dark-mode-dropdown .theme-options {
    background-color: var(--black);
}

.dark-mode .header-profile-dropdown .dark-mode-dropdown .theme-options button:hover {
    background-color: var(--g800);
}

.dark-mode .header-profile-dropdown .dark-mode-dropdown .theme-options button.active {
    background-color: var(--p900);
    color: var(--p200);
}

.dark-mode .sidebar-navigation .theme-menu .theme-submenu button {
    color: var(--black);
}

.dark-mode .modal-overlay,
.dark-mode .modal-backdrop,
.dark-mode .ui-modal-overlay,
.dark-mode .guided-setup-modal,
.dark-mode .modal-overlay-higher,
.dark-mode .email-modal {
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
}

.dark-mode .filter-close-button svg {
    fill: var(--black);
}

.dark-mode .filter-modal,
.dark-mode .header-profile-dropdown,
.dark-mode .more-tabs-dropdown,
.dark-mode .confirmation-dropdown {
    background-color: var(--black);
}

.dark-mode .confirmation-dropdown p {
    color: var(--g200);
}

.dark-mode .new-item-modal-content {
    background-color: var(--g700);
}

.dark-mode .modal-header,
.dark-mode .header-profile-dropdown li:hover {
    background-color: var(--g700);
}

.dark-mode .modal-header {
    border-bottom: 1px solid var(--g500);
}

.dark-mode .modal-content {
    background-color: var(--black);
    color: var(--g100);
}

.dark-mode .modal-lh-chart {
    fill: var(--g800);
}

.dark-mode .modal-lh-line {
    stroke: var(--g500);
}

.dark-mode .date-selector-dropdown {
    background-color: var(--black);
    border: 1px solid var(--g500);
    border-radius: 0;
}

.dark-mode .date-selector-dropdown li:hover {
    background-color: var(--g800);
}

.dark-mode .suggestions-menu {
    background-color: var(--black);
    border: 1px solid var(--g500);
}

.dark-mode .suggestion-item {
    color: var(--g100);
}

.dark-mode .attribute-popup {
    background-color: var(--g800);
}

/* Date Picker */
.dark-mode .react-datepicker {
    background-color: var(--black);
    border: 1px solid var(--g500) !important;
}

.dark-mode .react-datepicker__header {
    background-color: var(--g700) !important;
    border-bottom: 1px solid var(--g500) !important;
}

.dark-mode .react-datepicker__current-month,
.dark-mode .react-datepicker-time__header,
.dark-mode .react-datepicker-year-header,
.dark-mode .react-datepicker__day-name,
.dark-mode .react-datepicker__day,
.dark-mode .react-datepicker__time-name {
    color: var(--g200) !important;
}

.dark-mode .react-datepicker__day:hover {
    background-color: var(--g700) !important;
}

.dark-mode .react-datepicker__day--selected,
.dark-mode .react-datepicker__day--keyboard-selected,
.dark-mode .react-datepicker__day--in-selecting-range,
.dark-mode .react-datepicker__day--in-range {
    background-color: var(--p500) !important;
    color: var(--black) !important;
}

.dark-mode .react-datepicker__day--in-range:not(.react-datepicker__day--selected) {
    background-color: var(--p700) !important;
    color: var(--g200) !important;
}

.dark-mode .react-datepicker__day--outside-month {
    color: var(--g500) !important;
}

/* Pricing Table */
.dark-mode .pricing-tier-active {
    background-color: var(--g700);
    color: var(--g300);
}

.dark-mode .pricing-table-contract, 
.dark-mode .pricing-table-info-text,
.dark-mode .pricing-table-slider-label {
    color: var(--g200);
}

.dark-mode .pricing-tier-popular {
    background-color: var(--p500);
    color: var(--black);
}

.dark-mode .pricing-table-toggle span {
    color: var(--g200);
}

.dark-mode .pricing-table-toggle span.active {
    color: var(--p500);
}

.dark-mode .rc-slider-rail {
    background-color: var(--g500) !important;
    height: 10px !important; 
  }

/* Floating Navigation */
.dark-mode .floating-navigation svg {
    fill: var(--black);
}

/* Email Admins */
.dark-mode .email-input-container {
    background-color: var(--black);
}

.dark-mode .email-chip {
    background-color: var(--p900);
}

/* Email Modal */
.dark-mode .email-modal-content {
    background-color: var(--black);
}

/* Traits Tab */
.dark-mode .traits-tab {
    background-color: var(--black);
}

.dark-mode .line-background,
.dark-mode .tick {
    background-color: var(--g200);
}
  
.dark-mode .label {
    color: var(--g200);
  }

.dark-mode .attribute-details-popup {
background-color: var(--g800);
color: var(--g150);
}

.dark-mode .attribute-popup-name,
.dark-mode .attribute-details-popup strong {
    color: var(--g100);
}

/* Export Options */
.dark-mode .export-button .dropdown-button-icon,
.dark-mode .export-button .dropdown-icon {
    fill: var(--black);
}

.dark-mode .dropdown-button-menu {
    background-color: var(--black);
    border: 1px solid var(--g900);
}

.dark-mode .dropdown-button-menu .dropdown-button-item {
    color: var(--g200);
}

.dark-mode .dropdown-button-menu .dropdown-button-item:hover {
    background-color: var(--p900);
}

/* Collapsible Sections */
.dark-mode .section-header {
    background-color: var(--black);
}

.dark-mode .section-header:hover {
    background-color: var(--p900);
}

.dark-mode .section-header h2 {
    color: var(--g200);
}

.dark-mode .caret-icon {
    color: var(--g200);
}

.dark-mode .section-content {
    border-left-color: var(--p700);
}

/* Spectrum Comparison User */
.dark-mode .comparison-user-indicator {
    background-color: var(--black);
}

/* Tags */
.dark-mode .tag-chip,
.dark-mode button.tag-menu-button {
    color: var(--black);
}

/* Aria Chat */
.dark-mode .aria-chat-popup {
    background-color: var(--black);
    border: 1px solid var(--g700);
}

.dark-mode .aria-chat-popup .person-chip {
    background-color: var(--g700);
}

.dark-mode .aria-chat-popup .prompt-button {
    background-color: var(--g800);
    color: var(--g200);
}

.dark-mode .aria-chat-button svg {
    color: var(--black);
}

.dark-mode .profile-tag {
    background: var(--p700);
    color: var(--white);
}

/* Share Modal */
.dark-mode .share-modal-dropdown {
    background-color: var(--black);
    border: 1px solid var(--g500);
}

.dark-mode .share-modal-content {
    background-color: var(--black);
}

.dark-mode .share-modal-dropdown-item:hover {
    background-color: var(--g800);
}

.dark-mode .shared-users-container {
    background-color: var(--black);
    border: 1px solid var(--g500);
}

.dark-mode .shared-user-item {
    color: var(--g200);
    border-bottom: 1px solid var(--g700);
}

.dark-mode .shared-user-item:hover {
    background-color: var(--g800);
}

.dark-mode .shared-user-name {
    color: var(--g100);
}

.dark-mode .shared-user-email {
    color: var(--g300);
}

.dark-mode .advisor-chip {
    color: var(--black);
}

/* Quadrant Chart Table Hover Effects */
.dark-mode .quadrant-row-hover td {
    background-color: var(--g700) !important;
    color: var(--g100);
}

/* Quadrant Chart Tooltip */
.dark-mode .tooltip.q-chart-text {
    background-color: var(--g800) !important;
    border: 1px solid var(--g600) !important;
    color: var(--g100);
}

.dark-mode .tooltip.q-chart-text strong {
    color: var(--g50);
}

/* Assessment Stats */
.dark-mode .assessment-stats {
    background-color: var(--black);
    border: 1px solid var(--g700);
}

.dark-mode .assessment-stats .stats-row {
    color: var(--g200);
}

.dark-mode .assessment-stats .stats-row strong {
    color: var(--g100);
}

.dark-mode .assessment-stats .stats-row span {
    color: var(--g200);
}

/* Position Status */
/* Position Status Dark Mode Styles */
.dark-mode .position-status-container {
    background-color: var(--g700);
}

.dark-mode .position-type-section h2 {
    color: var(--g100);
}

.dark-mode .current-status {
    background-color: var(--g800);
}

.dark-mode .status-label {
    color: var(--g300);
}

.dark-mode .status-value {
    color: var(--g100);
}

.dark-mode .conversion-info {
    color: var(--g200);
}

/* Settings Tab Dark Mode Styles */
.dark-mode .settings-section {
    background-color: var(--black);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.dark-mode .settings-section h4 {
    color: var(--g200);
}

.dark-mode .settings-section h4 {
    color: var(--g200);
}

.dark-mode .role-description {
    background-color: var(--g700);
    color: var(--g200);
}

/* Position Type Chip Dark Mode */
.dark-mode .position-type-chip.active {
    background-color: var(--p900);
    color: var(--p300);
}

.dark-mode .position-type-chip.internal {
    background-color: var(--g700);
    color: var(--g200);
}

/* Premium Report Navigation */
.dark-mode .report-nav {
    background-color: var(--black);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.dark-mode .report-nav li {
    color: var(--g200);
}

.dark-mode .report-nav li:hover {
    background-color: var(--g700);
}

.dark-mode .report-nav li.active {
    background-color: var(--p700);
    color: var(--g100);
}

/* Dark Mode Scrollbar */
.dark-mode .report-nav::-webkit-scrollbar-track {
    background: var(--g700);
}

.dark-mode .report-nav::-webkit-scrollbar-thumb {
    background: var(--g500);
}

.dark-mode .report-nav::-webkit-scrollbar-thumb:hover {
    background: var(--g400);
}

/* Guided Setup Modal Dark Mode */
.dark-mode .setup-modal-close-icon svg {
    fill: var(--g700);
}

.dark-mode .setup-step-indicator {
    background-color: var(--g700);
    color: var(--g200);
}

.dark-mode .setup-instructions {
    background-color: var(--g800);
}

.dark-mode .setup-instructions .instruction-title,
.dark-mode .setup-instructions .instruction-note {
    color: var(--g100);
}

.dark-mode p.note {
    background-color: var(--g800);
    color: var(--g300);
}

.dark-mode .setup-instructions li {
    color: var(--g300);
}

.dark-mode .setup-step input.search-bar-input {
    background-color: black;
}

/* Role Category Banner Dark Mode */
.dark-mode .role-category-banner {
    background-color: var(--g800);
    border-left: 4px solid var(--p700);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.dark-mode .role-category-banner .banner-content {
    color: var(--g200);
}

.dark-mode .role-category-banner .button-text {
    color: var(--p300);
}

.dark-mode .role-category-banner .banner-close {
    color: var(--g400);
}

.dark-mode .role-category-banner .banner-close:hover {
    color: var(--g200);
}

.dark-mode .highlight-section {
    background-color: var(--g700) !important;
    box-shadow: 0 0 0 0 rgba(var(--p700-rgb), 0.4);
    animation: dark-highlight-pulse 1.5s ease-out;
}

@keyframes dark-highlight-pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(var(--p700-rgb), 0.4);
    }
    70% {
        box-shadow: 0 0 0 10px rgba(var(--p700-rgb), 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(var(--p700-rgb), 0);
    }
}

/* Role Category Section Styles */
.dark-mode .role-category-section {
    background-color: var(--g800);
}

.dark-mode .role-category-selector {
    background-color: var(--g800);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
}

.dark-mode .role-category-selector h3 {
    color: var(--g100);
}

.dark-mode .role-category-selector label {
    color: var(--g200);
}

.dark-mode .role-category-selector select, 
.dark-mode .role-category-modal-select {
    border-color: var(--g600);
    background-color: var(--black);
    color: var(--g200);
}

.dark-mode .role-category-value {
    background-color: var(--g700);
    border-color: var(--g600);
    color: var(--g200);
}

.dark-mode .confirmation-actions {
    background-color: var(--g700);
}

.dark-mode .confirmation-message {
    color: var(--g200);
}