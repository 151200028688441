/* Styles for the "More" button */
.more-button {
    display: none; /* Hide by default, will be shown on mobile */
}

/* Styles for dropdown items */
.dropdown-item {
    display: block; /* Items in the dropdown are block-level for better layout */
    width: 100%; /* Full width for better tap targets */
}

@media screen and (max-width: 1024px) {
    .workstyles-chart-container,
    .target-chart-container,
    .resonance-chart-container,
    .target-header,
    .workstyles-chart-heading {
        max-width: 80%;
    }

    .target-overlay {
        font-size: 13px;
    }

    /* Quadrant Chart */
    .quadrant-table-wrapper {
        display: none;
    }
}

@media (max-width: 768px) {
    .dropdown-button-menu {
        top: auto;
    }

    .linked-positions-container .dropdown-button-menu {
        position: fixed;
        top: 50%; /* Center vertically */
        left: 50%; /* Center horizontally */
        transform: translate(-50%, -50%); /* Center adjust */
        max-height: 60vh; /* Limit height to prevent bottom nav overlap */
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        width: calc(100% - 32px); /* Full width minus margins */
        max-width: 300px;
    }

    .main-content {
        padding: 0;
        margin-bottom: 80px;
    }

    .sidebar, .header-profile-container {
        display: none;
    }

    input {
        font-size: 16px !important;
    }

    .date-picker input {
        font-size: 16px !important;
    }

    .date-picker input::placeholder {
        font-size: 12px !important;
    }

    .profile-image {
        display: none;
    }

    .layout-profile-icon {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        aspect-ratio: 1/1;
        object-fit: cover;
    }

    .hamburger-menu {
        display: block;
    }

    .mobile-navigation {
        display: block;
    }

    .content-area {
        margin-left: 0;
        padding-top: 82px;
        width: 100%;
    }

    .recent-assessments-name {
        width: 40%;
    }

    .content-wrapper {
        padding: 0 .5rem;
    }

    .tab-content {
        padding: 20px .5rem;
    }

    .page-title-container {
        padding: 20px 0.5rem;
        justify-content: flex-start;
    }

    .back-button {
        margin-right: 6px;
    }

    .position-title .title-wrapper h1,
    .folder-title .title-wrapper h1,
    .dashboard-title .title-wrapper h1 {
        font-size: 1.25rem;
        margin-bottom: 0;
        line-height: 1.3rem;
    }

    .title-date-calculated {
        font-size: 0.875rem;
    }

    .layout-job-title {
        font-size: 0.875rem;
        margin-top: -2px;
    }

    .content-after-title {
        margin-top: 10px;
        width: 100%;
    }

    .content-wrapper table, .tab-content table, .infinite-scroll-component__outerdiv {
        width: calc(100% + 1rem);
        margin-left: -.5rem;
        margin-right: -.5rem;
    }

    /* .infinite-scroll-component__outerdiv{
        padding-inline: 8px;
    } */

    .group-members-section, .recent-assessments-section {
        flex: 1 1 100%;
    }

    .add-group-users {
        max-width: 100%;
    }

    .notification-row {
        width: 100%;
    }

    .hide-on-mobile {
        display: none;
    }

    .entity-details-container, .user-details-container {
        width: 100%;
    }

    .content-after-title div {
        font-size: 0.9rem;
    }

    .tab-item {
        font-size: 12px;
    }

    .more-button {
        display: inline-flex;
    }

    .more-tabs-dropdown {
        display: block;
    }

    .adjective-selection-table table td {
        font-size: 0.7rem;
      }
    
      .adjective-selection-table td {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
      }

    .target-survey-container {
        padding: 0;
    }

    .toggle-button {
        padding: 8px 8px;
        font-size: 12px;
    }

    .likert-text {
        font-size: 0.8rem;
    }

    .likert-scale-label {
        height: 110px;
    }

    .workstyles-chart-container,
    .resonance-chart-container,
    .workstyles-chart-heading {
        max-width: calc(100% + 40px);
        /*width: calc(100% + 40px);*/
        margin-left: -10px;
        margin-right: -10px;
    }

    .target-chart-container,
    .target-header {
        max-width: 100%;
    }

    .target-chart-container {
        display: block;
    }

    .workstyles-text {
        font-size: 20px;
    }

    .standalone-text {
        font-size: 18px;
    }

    .iscp-circles {
        filter: drop-shadow(var(--filter-large));
    }

    .workstyles-svg,
    .target-svg,
    .resonance-chart-container {
        transition: max-width 0.3s ease;
        filter: drop-shadow(var(--filter-intense));
    }

    /* Target Users Table */
    /* .name-cell {
        min-width: 120px;
        font-size: 13px;
    } */

    .iscp-container {
        max-width: 100px;
        max-height: 35px;
    }

    .table-profile, .table-archetype {
        font-size: 12px;
    }

    /* Job Title Input/Edit */
    .job-title-input > input {
        font-size: 11px !important;
        padding: 6px 10px;
        min-width: 100px;
    }

    .job-title-input .button .job-title-button-text {
        display: none;
    }
    
    .job-title-input .button .job-title-button-icon {
        display: inline;
        margin: 0;
    }

    .job-title-input .button {
        padding: 6px 8px;
    }

    /* Banner */
    .role-category-banner {
        display: none;
    }

    /* Position Setup Guide */
    .guided-setup-header .modal-title {
        font-size: 1.2rem;
        text-align: left;
    }

    .guided-setup-header .setup-step-indicator {
        font-size: 0.9rem;
    }
    
    /* Floating Navigation */
    .floating-navigation {
        bottom: 57px;
        right: 55px; 
      }
      
      .nav-button {
        width: 30px;
        height: 30px;
      }

      .floating-navigation svg {
        width: 18px;
        height: 18px;
    }

    /* Profile Details Responsive Styles */
    .profile-details-container {
        flex-direction: column;
        gap: 1rem;
    }

    .profile-sidebar {
        flex: 1;
        width: 100%;
        gap: 1rem;
    }

    .profile-main-content {
        width: 100%;
        gap: 1rem;
    }

    .profile-section {
        padding: 15px;
    }

    .profile-section h3 {
        font-size: 1.1rem;
        margin-bottom: 0.75rem;
    }

    .profile-list {
        padding-left: 1.25rem;
    }

    .profile-list li {
        font-size: 0.9rem;
        margin-bottom: 0.4rem;
        line-height: 1.4;
    }

    .profile-text-content {
        font-size: 0.9rem;
        line-height: 1.4;
    }

    /* Profile Header Responsive Adjustments */
    .profile-heading-bar {
        gap: 10px;
    }

    .profile-name {
        font-size: 1.3rem;
    }

    .profile-shine {
        font-size: 0.9rem;
    }

    .tags-container {
        margin-left: 0;
        padding: 0 0.5rem 16px;
        margin-top: 8px;
    }

    .tags-container.mobile-only {
        display: flex;
    }

    .mobile-only .tag-chip {
        font-size: 11px;
    }

    .content-after-title .tags-container {
        margin-top: 18px;
    }

    .export-button-wrapper {
        margin-left: auto;
        padding-left: 10px;
    }
    
    .content-after-title-wrapper {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    .export-button-wrapper {
        top: 102px;
        right: 11px;
    }

    .date-column {
        font-size: 12px;
    }

    /* Settings Page Specific */
    .settings-container {
        padding: 0 0.5rem;
    }

  .notification-row {
    flex-direction: column;
    gap: 10px;
    margin-top: 12px;
  }

  .notification-input,
  .notification-select {
    width: 100%;
    margin-right: 0;
  }

  .notification-actions {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    margin-top: 16px;
  }

  /* Tag Settings */
  .tags-list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }

  .tag-row {
    width: 100%;
    justify-content: space-between;
    padding: 8px;
  }

  .add-tag {
    flex-direction: column;
    gap: 10px;
  }

  .add-tag input {
    width: 100%;
  }

  /* Help text */
  .help-text {
    font-size: 0.85rem;
    line-height: 1.4;
  }

  /* Profile Avatar Responsive Adjustments */
  .dashboard-profile-avatar {
    height: 80px;
    width: 80px;
}


.dashboard-profile-text-content h2 {
    font-size: 1.2rem;
    margin-bottom: 4px;
}

.dashboard-profile-text-content h3 {
    font-size: 1rem;
    margin: 0 0 4px 0;
}

.dashboard-profile-description {
    font-size: 0.8rem;
    margin: 8px 0;
    line-height: 1.4;
}
}

@media (max-width: 768px) {
    .hide-on-mobile {
        display: none !important;
    }
}

@media (min-width: 768px) {
    .hide-on-desktop {
        display: none !important;
    }
}

/* Info Button & Content After Title */
@media (max-width: 768px) {
    .mobile-only {
        display: block;
    }
    
    .desktop-only {
        display: none;
    }
    
    .assessment-details-header-container {
        justify-content: flex-end;
        width: 100%;
    }

    .content-after-title.mobile-only {
        margin-bottom: 10px;
        width: 100%;
        padding: 10px;
        text-align: right;
    }

    .info-button {
        display: block;
        color: var(--p500);
        cursor: pointer;
    }
    .header-button-container h2 {
        font-size: 24px;
        line-height: 1.6rem;
    }
}

/* Prevent Table from being too wide on mobile */
@media screen and (max-width: 768px) {
    .assessment-table-container {
      overflow-x: hidden;
    }
  
    .assessment-table-container td .name-cell {
      max-width: 29vw; 
      overflow: hidden;
      text-overflow: ellipsis;
    }
  
    /* Ensure the container doesn't allow horizontal scrolling */
    .content-wrapper, 
    .tab-content {
      overflow-x: hidden;
      width: 100%;
    }
  }

.mobile-only-icon {
    display: none;
}

@media (max-width: 768px) {
    .mobile-only-icon {
        display: block;
    }
}

@media (min-width: 769px) {
    .tags-container.desktop-only {
        display: flex;
    }
    
    .content-after-title {
        display: block;
    }

    .mobile-only {
        display: none;
    }

    .mobile-only-icon {
        display: none;
    }
}

@media (max-width: 768px) and (orientation: landscape) {
    .workstyles-chart-container,
    .resonance-chart-container {
        max-width: 65%;
        /*width: calc(100% + 40px);*/
        margin-left: -10px;
        margin-right: -10px;
    }
}

/* Modal Responsive Styles */
@media screen and (max-width: 768px) {
    .modal-content, 
    .share-modal-content, 
    .filter-modal, 
    .ui-modal-content,
    .email-modal-content,
    .guided-setup-content {
        width: 95%;
        padding: 20px 13px;
        margin: 0;
    }

    .modal-overlay,
    .modal-backdrop,
    .ui-modal-overlay,
    .guided-setup-modal,
    .email-modal {
        /* Ensure backdrop blur works correctly on mobile devices */
        -webkit-overflow-scrolling: touch;
    }
}
