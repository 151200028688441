.sidebar {
    width: 76px; /* Narrow width by default */
    padding-top: 0;
    height: 100vh;
    position: fixed;
    left: 0;
    background-color: var(--secondary);
    color: white;
    z-index: 1000;
    transition: width 0.3s ease;
    display: flex;
    flex-direction: column; /* Stack children vertically */
}

.sidebar-logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 46px 0;
    height: 60px;
    transition: all 0.3s ease;
    flex-shrink: 0;
    overflow: hidden; 
}

/* New wrapper for navigation */
.sidebar-navigation-wrapper {
    flex-grow: 1;
    overflow-y: auto; /* Enable scrolling */
    overflow-x: hidden;
    margin-bottom: 1rem; /* Space at bottom */
}

.sidebar-navigation {
    list-style: none;
    padding: 6px;
    margin: 0;
}

/* Add scrollbar styling for better visibility */
.sidebar-navigation-wrapper::-webkit-scrollbar {
    width: 4px;
}

.sidebar-navigation-wrapper::-webkit-scrollbar-track {
    background: transparent;
}

.sidebar-navigation-wrapper::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 2px;
}

.sidebar-navigation-wrapper::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.3);
}

.sidebar-logo {
    height: 40px; /* Smaller size by default */
    transition: height 0.3s ease;
}

.sidebar:hover {
    width: 180px; /* Expanded width on hover */
    box-shadow: 4px 0 15px rgba(0, 0, 0, 0.4); /* Shadow on hover */
}

.sidebar:hover .sidebar-logo {
    height: 58px; /* Full size when expanded */
}

.sidebar-navigation li a {
    padding: 12px 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    border-radius: 6px;
    transition: background-color 0.3s ease, padding 0.3s ease;
    text-decoration: none;
    margin-bottom: 0.5rem;
}

.sidebar-navigation li svg {
    margin-right: 16px;
    width: 32px;
    height: 32px;
    flex-shrink: 0; /* Prevent icon from shrinking */
}

.sidebar-navigation li a span {
    display: none; /* Hide link text by default */
    margin-left: 10px;
    white-space: nowrap; /* Prevent text from wrapping */
}

.sidebar:hover .sidebar-navigation li a {
    padding-left: 16px;
}

.sidebar:hover .sidebar-navigation li a span {
    display: inline; /* Show link text on hover */
}

.sidebar-navigation li a,
.sidebar-navigation li svg {
    color: var(--white);
    opacity: .6;
}

.sidebar-navigation li a.active,
.sidebar-navigation li a.active svg,
.sidebar-navigation li a:hover,
.sidebar-navigation li a:hover svg {
    color: var(--white);
    opacity: 1;
}

@media screen and (max-width: 1024px) {
    .sidebar-navigation li a {
        font-size: 12px;
    }

    .sidebar-navigation li svg {
        width: 26px;
        height: 26px;
    }
}

@media (max-width: 768px) {
    .mobile-navigation {
        position: fixed;  /* Change from absolute to fixed */
        top: 54px;  /* Match the header height */
        left: 0;
        width: 100%;
        height: 100vh;  /* Full height minus header */
        background-color: var(--p500);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        z-index: 1100;  /* Ensure it's above other content */
        overflow-y: auto;
        pointer-events: auto;  /* Ensure clicks are captured */
    }

    .mobile-navigation .sidebar-navigation {
        padding: 20px 10px;
        height: 100%;
        pointer-events: auto;  /* Ensure clicks are captured */
    }

    .mobile-navigation .sidebar-navigation li a {
        flex-direction: row;
        justify-content: flex-start;
        /*padding: 10px;*/
    }

    .sidebar-navigation li a span {
        display: flex; /* Hide link text by default */
        margin-left: 10px;
        white-space: nowrap; /* Prevent text from wrapping */
    }

    .mobile-navigation .sidebar-navigation li svg {
        margin-right: 10px;
        margin-bottom: 0;
    }

    li.mobile-logout {
        display: flex;
    }
}

.sidebar-navigation li a:hover, .sidebar-navigation li a.active {
    background-color:  hsla(0,0%,100%,.06);
}

.navigation-links,
.sidebar-navigation {
    list-style: none;
    padding: 6px;
    margin: 0;
}

.navigation-links li,
.sidebar-navigation li {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    color: var(--white);
    font-size: 13px;
    font-weight: var(--semi-bold);
    flex-direction: column;
}

li.mobile-logout {
    display: none;
}

/* Theme Menu */
.sidebar-navigation .theme-menu {
    position: relative;
}

.sidebar-navigation .theme-menu .theme-submenu {
    list-style: none;
    padding: 0;
    margin: 0;
    background-color: hsla(0, 0%, 100%, .03);
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
    position: static;
    width: 100%;
}

.sidebar-navigation .theme-menu.open .theme-submenu {
    max-height: 200px; 
}

.sidebar-navigation .theme-menu .theme-submenu li {
    padding: 0;
}

.sidebar-navigation .theme-menu .theme-submenu button {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 12px 16px 12px 28px;
    border: none;
    background: none;
    cursor: pointer;
    text-align: left;
    font-family: var(--text);
    color: var(--white);
    opacity: 0.6;
    transition: opacity 0.3s ease, background-color 0.3s ease;
}

.sidebar-navigation .theme-menu .theme-submenu button:hover,
.sidebar-navigation .theme-menu .theme-submenu button:focus {
    opacity: 1;
    background-color: hsla(0,0%,100%,.06);
}

.sidebar-navigation .theme-menu .theme-submenu button svg {
    margin-right: 10px;
}

/* Ensure the submenu is initially hidden */
.sidebar-navigation .theme-menu:not(.open) .theme-submenu {
    display: none;
}