.statistics-boxes {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    gap: 1rem;
}

.stat-box {
    background-color: var(--white);
    border-radius: 8px;
    padding: 1rem;
    display: flex;
    align-items: center;
    flex: 1;
    min-width: 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    cursor: pointer;
}

.stat-box:hover {
    transform: translateY(-5px);
}

.stat-box svg {
    color: var(--primary);
    margin-right: 1rem;
    flex-shrink: 0;
}

.dashboard-stat-icon {
    font-size: 36px;
}

.stat-info {
    display: flex;
    flex-direction: column;
    min-width: 0;
    overflow: hidden;
}

.stat-info h3 {
    font-size: 1.7rem;
    margin: 0;
    color: var(--g900);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.stat-info p {
    font-size: 1rem;
    margin: 0;
    color: var(--g600);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (max-width: 768px) {
    .statistics-boxes {
        gap: 0.5rem;
    }
    
    .stat-box {
        padding: 0.8rem 1rem;
    }

    .stat-box p {
        display: none;
    }

    .dashboard-stat-icon {
        font-size: 20px;
    }

    .stat-info h3 {
        font-size: 0.9rem;
    }

    .stat-box svg {
        color: var(--primary);
        margin-right: 0.5rem;
    }
}