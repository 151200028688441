/* Pricing Table Container */
.pricing-table-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 1.5rem;
  }
  
  .pricing-table-title {
    font-size: 1.75rem;
    text-align: center;
    margin-bottom: 1.5rem;
    color: var(--g900);
  }
  
  /* Slider Styles */
  .pricing-table-slider-container {
    margin-bottom: 2rem;
  }
  
  .pricing-table-slider-label {
    display: block;
    margin-bottom: 0.75rem;
    font-size: 1rem;
    color: var(--g700);
  }
  
  .rc-slider {
    margin-bottom: 2rem;
  }
  
  .rc-slider-rail {
    background-color: var(--g200) !important;
    height: 10px !important; 
  }
  
  .rc-slider-track {
    background-color: var(--p500) !important;
  }
  
  .rc-slider-handle {
    border-color: var(--p500) !important;
    background-color: var(--white) !important;
    width: 20px !important;
    height: 20px !important; 
    margin-top: -5px !important; 
  }
  
  .rc-slider-handle:hover {
    border-color: var(--p600) !important;
  }
  
  .rc-slider-handle:active {
    border-color: var(--p700) !important;
    box-shadow: 0 0 5px var(--p500) !important;
  }

  .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    border-color: var(--p500) !important;
    box-shadow: 0 0 5px var(--p500) !important;
  }
  
  /* Pricing Tiers Container */
  .pricing-table-tiers-container {
    display: flex;
    justify-content: center;
    gap: 2rem;
    flex-wrap: wrap;
  }
  
  /* Individual Pricing Tier */
  .pricing-tier {
    flex: 1;
    min-width: 220px;
    max-width: 300px;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    position: relative;
    cursor: pointer;
  }
  
  .pricing-tier:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  .pricing-tier-active {
    background-color: var(--white);
  }
  
  .pricing-tier-disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
  
  .pricing-tier-popular {
    background-color: var(--p500);
    color: var(--white);
    transform: scale(1.05);
  }
  
  .pricing-tier-popular:hover {
    transform: scale(1.05) translateY(-5px);
  }
  
  .pricing-tier-title {
    font-size: 1.25rem;
    margin-bottom: 0.75rem;
  }
  
  .pricing-tier-price {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 0.75rem;
  }
  
  .pricing-tier-monthly-text {
    font-size: 1rem;
    font-weight: normal;
  }
  
  .pricing-tier-description {
    margin-bottom: 1.5rem;
    line-height: 1.4;
    font-size: 0.9rem;
  }
  
  .pricing-tier-button {
    display: block;
    width: 100%;
    padding: 0.75rem;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .pricing-tier-button-active {
    background-color: var(--p500);
    color: var(--white);
  }
  
  .pricing-tier-button-active:hover {
    background-color: var(--p600);
  }
  
  .pricing-tier-button-disabled {
    background-color: var(--g300);
    color: var(--g700);
    cursor: not-allowed;
  }
  
  .pricing-tier-popular .pricing-tier-button-active {
    background-color: var(--white);
    color: var(--p500);
  }
  
  .pricing-tier-popular .pricing-tier-button-active:hover {
    background-color: var(--g100);
  }
  
  .pricing-tier-most-popular {
    position: absolute;
    top: -12px;
    right: 20px;
    background-color: var(--p700);
    color: var(--white);
    padding: 0.25rem 1rem;
    border-radius: 20px;
    font-size: 0.9rem;
    font-weight: bold;
  }

  .pricing-table-info-text {
    font-size: 1.1rem;
    font-weight: var(--semi-bold);
  }
  
  /* Responsive Design */
  @media (max-width: 1024px) {
    .pricing-table-tiers-container {
      flex-direction: column;
      align-items: center;
      gap: 1rem;
    }
  
    .pricing-tier {
      width: 100%;
      max-width: 400px;
      padding: 1.25rem;
    }
  
    .pricing-tier-popular {
      transform: none;
      order: -1;
    }
  
    .pricing-tier-popular:hover {
      transform: translateY(-5px);
    }
  }

/* Toggle Switch */
.pricing-table-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.5rem;
  }

.pricing-table-toggle span {
  color: var(--g700);
  padding: 0 1rem;
}

.pricing-table-toggle span.active {
  color: var(--p500);
  font-weight: bold;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--g300);
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: var(--p500);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--p500);
}

input:checked + .slider:before {
  transform: translateX(26px);
}

/* Additional Information */
.pricing-table-info {
  text-align: center;
  margin-top: 2rem;
  color: var(--g700);
  font-size: 0.9rem;
}

.pricing-table-info p {
  margin: 0.5rem 0;
}

/* Feature Comparison Table */
.feature-comparison-table {
  margin-top: 3rem;
  padding: 0 1rem;
}

.feature-comparison-table h2 {
  text-align: center;
  color: var(--p500);
}

.feature-comparison-table table {
  max-width: 800px;
  margin: 0 auto;
}

.feature-comparison-table table tr {
  height: 40px;
}

.feature-table-footnotes {
  max-width: 1000px;
  margin: 2rem auto;
  padding: 1rem;
  font-size: 0.7rem;
  color: var(--g700);
}

.feature-table-footnotes ul {
  margin: 0.5rem 0 0 1.5rem;
}

.feature-table-footnotes li {
  margin-bottom: 0.25rem;
}

.feature-comparison-table .feature-check {
  color: var(--green);
  font-size: 1.2rem;
}

.feature-comparison-table .feature-x {
  color: var(--red);
  font-size: 1.2rem;
}

/* Hiring Rate Selector */
.hiring-rate-selector {
  margin: 2rem 0;
  text-align: center;
}

.hiring-rate-selector h2 {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  color: var(--p500);
}

.hiring-rate-buttons {
  display: flex;
  gap: 1.5rem;
  justify-content: center;
  margin-top: 1rem;
}

.hiring-rate-button {
  padding: 1.1rem 1.2rem;
  border: none;
  border-radius: 8px;
  background: var(--white);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  min-width: 200px;
  font-size: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  color: var(--g900);
}

.hiring-rate-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.hiring-rate-button.active {
  background: var(--p500);
  color: var(--white);
  transform: scale(1.05);
}

.hiring-rate-button.active:hover {
  transform: scale(1.05) translateY(-3px);
}

.hiring-count {
  font-size: 0.8em;
  opacity: 0.9;
}

/* Add responsive design for hiring rate buttons */
@media (max-width: 768px) {
  .hiring-rate-buttons {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .hiring-rate-button {
    width: 100%;
    max-width: 300px;
  }

  .hiring-rate-button.active {
    transform: scale(1.02);
  }

  .hiring-rate-button.active:hover {
    transform: scale(1.02) translateY(-3px);
  }
}

.pricing-tier-selected {
  background-color: var(--p500);
  color: var(--white);
  transform: scale(1.05);
}

.pricing-tier-selected:hover {
  transform: scale(1.05) translateY(-5px);
}

/* Keep the Most Popular tag visible even when not selected */
.pricing-tier-popular:not(.pricing-tier-selected) {
  background-color: var(--white);
  color: var(--g900);
}

.pricing-tier-popular:not(.pricing-tier-selected) .pricing-tier-most-popular {
  background-color: var(--p700);
  color: var(--white);
}

/* Ensure proper contrast for prices when selected */
.pricing-tier-selected .pricing-tier-price,
.pricing-tier-selected .pricing-tier-monthly-text,
.pricing-tier-selected .pricing-tier-description {
  color: var(--white);
}

/* Add focus styles for accessibility */
.pricing-tier:focus {
  outline: none;
  box-shadow: 0 0 0 3px var(--p200);
}

@media (max-width: 1024px) {
  .pricing-tier-selected {
    transform: none;
  }

  .pricing-tier-selected:hover {
    transform: translateY(-5px);
  }
}

/* ROI Calculator Styles */
.roi-calculator {
  margin-top: 2rem;
  /* padding: 2rem;
  background: var(--white);
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
}

.roi-calculator h2 {
  text-align: center;
  color: var(--p500);
}

.roi-assumptions {
  margin-bottom: 2rem;
}

.assumption-selectors {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
}

.assumption-group {
  display: flex;
  gap: 2rem;
  align-items: flex-start;
  justify-content: center;
}

.select-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.select-group label {
  font-size: 0.9rem;
  font-weight: var(--normal);
  color: var(--g700);
  text-align: left;
}

.select-group select {
  padding: 0.5rem;
  border: 1px solid var(--g300);
  border-radius: 4px;
  font-size: 1rem;
  min-width: 200px;
}

.select-group select:focus {
  outline: none;
  border-color: var(--p500);
  box-shadow: 0 0 0 2px var(--p200);
}

@media (max-width: 1024px) {
  .assumption-group {
    flex-direction: column;
    align-items: stretch;
    gap: 1rem;
  }
  
  .select-group {
    width: 100%;
  }
}

.roi-summary {
  margin-top: 2rem;
}

.roi-metrics {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  margin-bottom: 2rem;
}

.roi-metric-box {
  background-color: var(--white);
  border-radius: 8px;
  padding: 1.25rem;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.roi-metric-box:hover {
  transform: translateY(-5px);
}

.roi-metric-icon {
  color: var(--p500);
  font-size: 36px;
  margin-right: 1rem;
}

.roi-metric-info {
  display: flex;
  flex-direction: column;
}

.roi-metric-info h3 {
  font-size: 1.5rem;
  margin: 0;
  color: var(--g900);
  text-align: left;
}

.roi-metric-info p {
  font-size: 0.9rem;
  margin: 0;
  color: var(--g600);
}

@media (max-width: 1024px) {
  .roi-metrics {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 640px) {
  .roi-metrics {
    grid-template-columns: 1fr;
  }
  
  .roi-metric-box {
    padding: 1rem;
  }

  .roi-metric-icon {
    font-size: 24px;
  }

  .roi-metric-info h3 {
    font-size: 1.2rem;
  }
}

.roi-assumptions-display {
  padding: 1rem;
  background: var(--g100);
  border-radius: 8px;
}

.roi-assumptions-display h4 {
  color: var(--g700);
  margin-bottom: 1rem;
}

.roi-assumptions-display ul {
  list-style: none;
  padding: 0;
}

.roi-assumptions-display li {
  margin-bottom: 0.5rem;
  color: var(--g700);
  font-size: 0.9rem;
}

.assumptions-category {
  font-weight: var(--semi-bold);
}

.total-estimate {
  font-weight: var(--bold);
}

@media (max-width: 768px) {
  .assumption-selectors {
    flex-direction: column;
  }
  
  .roi-metrics {
    grid-template-columns: 1fr;
  }
}

.improvement-rate-selector {
  margin: 2rem 0;
  text-align: center;
}

.improvement-rate-selector h3 {
  margin-bottom: 1rem;
  color: var(--g700);
}

.improvement-rate-select {
  padding: 0.5rem;
  border: 1px solid var(--g300);
  border-radius: 4px;
  font-size: 1rem;
  min-width: 200px;
}

.improvement-rate-select:focus {
  outline: none;
  border-color: var(--p500);
  box-shadow: 0 0 0 2px var(--p200);
}

.roi-calculator h3 {
  text-align: center;
  margin-bottom: 1.5rem;
  color: var(--g900);
}

/* Ensure this doesn't affect other h3s outside the calculator */
.pricing-table-container h3:not(.roi-calculator h3) {
  text-align: left;
}

.roi-metric-info h3 {
  margin-bottom: 0;
  font-size: 1.5rem;
  text-align: left;
}

.roi-metric-info p {
  text-align: left;
}

.roi-assumptions-display ul ul {
  margin-left: 1.5rem;
  margin-top: 0.5rem;
}

.roi-assumptions-display ul ul li {
  margin-bottom: 0.25rem;
}

.conservative-note {
  margin-top: 1.2rem;
}

.conservative-note ul {
  margin-top: 0.5rem;
}

.company-name-display {
  display: none !important;
}