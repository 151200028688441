.permission-manager {
    margin-bottom: 20px;
}

.permission-manager h3 {
    margin-bottom: 15px;
    font-size: 18px;
    color: var(--primary);
}

.permission-category {
    margin-bottom: 10px;
}

.permission-item {
    padding: 2px 0;
}

.permission-item.child {
    margin-left: 20px;
}

.permission-item label {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.children {
    padding-left: 20px;
    border-left: 2px solid var(--g200);
}

.permission-item > label {
    font-weight: bold;
}

.permission-item.child > label {
    font-weight: normal;
}

/*.permission-item:hover {*/
/*    background-color: var(--g150);*/
/*}*/

/*.permission-item.child:hover {*/
/*    background-color: var(--g150);*/
/*}*/

/* Radio button specific styles */
.permission-item input[type="radio"] + span {
    margin-right: 15px;
}

/* Styles for radio button groups */
.radio-group {
    display: flex;
    align-items: center;
}

.radio-group label {
    margin-right: 15px;
}