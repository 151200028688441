/* Base Loading State */
[data-loading="true"] {
  position: relative;
}

/* Loading State Placeholder Styles */
[data-loading="true"] td {
  position: relative;
  overflow: hidden;
}

[data-loading="true"] td::before {
  content: "";
  display: block;
  height: 20px;
  width: 80%;
  background-color: var(--g150);
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}

/* Shimmer Animation */
[data-loading="true"] td::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0.2) 20%,
    rgba(255, 255, 255, 0.5) 60%,
    rgba(255, 255, 255, 0)
  );
  animation: shimmer 2s infinite;
  pointer-events: none;
  z-index: 1;
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

/* Column-specific styles */
[data-loading="true"] td.firstlastname-column::before,
[data-loading="true"] td.email-column::before,
[data-loading="true"] td.position-column::before {
  width: 80%;
}

[data-loading="true"] td.profile-column::before {
  height: 24px;
  width: 24px;
  border-radius: 50%;
}

[data-loading="true"] td.iscp-column::before {
  height: 28px;
  width: 100px;
  margin: 0 auto;
}

[data-loading="true"] td.date-column::before {
  width: 60px;
}

/* Dark Mode Loading States */
.dark-mode [data-loading="true"] td::before {
  background-color: var(--g700);
}

.dark-mode [data-loading="true"] td::after {
  background-image: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 0,
    rgba(255, 255, 255, 0.02) 20%,
    rgba(255, 255, 255, 0.04) 60%,
    rgba(0, 0, 0, 0)
  );
  opacity: 0.7;
}

.dark-mode .stat-box[data-loading="true"] .stat-info h3,
.dark-mode .stat-box[data-loading="true"] .stat-info p {
  background-color: var(--g700);
}

.dark-mode .assessment-table-container tr[data-loading="true"] td::before {
  background-color: var(--g700);
}

/* Specific Component Overrides */
.stat-box[data-loading="true"] .stat-info h3,
.stat-box[data-loading="true"] .stat-info p {
  background-color: var(--g100);
  border-radius: 4px;
  min-width: 60px;
}

.assessment-table-container tr[data-loading="true"] td::before {
  content: "";
  display: block;
  height: 20px;
  background-color: var(--g150);
  border-radius: 4px;
  width: 80%;
}

.assessment-table-container tr[data-loading="true"]:first-child td {
    padding-top: 18px;
}

.assessment-table-container tr[data-loading="true"] td.firstlastname-column,
.assessment-table-container tr[data-loading="true"] td.email-column,
.assessment-table-container tr[data-loading="true"] td.position-column {
  width: 22%;
}

.assessment-table-container tr[data-loading="true"] td.profile-column::before {
    height: 24px;
}

.assessment-table-container tr[data-loading="true"] td.iscp-column::before {
    height: 28px;
    width: 100px;
    margin: 0 auto;
}

/* Loading State Opacity */
[data-loading="true"] .loading-fade {
  opacity: 0.3;
}