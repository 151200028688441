.group-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 00px;
}

.group-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 110px;
}

.group-icon {
    width: 80px;
    height: 80px;
}

.table-group-icon {
    width: 20px;
    height: 20px;
    padding-top: 4px;
}

.group-name {
    text-align: center;
    font-size: 12px;
    font-weight: var(--semi-bold);
    width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.all-groups-heading {
    margin-top: 30px;
}

.group-icon .group-dark,
.table-group-icon .group-dark {
    fill: var(--p500);
}

.group-icon .group-lite,
.table-group-icon .group-lite {
    fill: var(--p300);
}

a.new-group-item {
    color: var(--g500);
}

.group-details-container {
    display: flex;
    flex-wrap: wrap;
    margin: -15px;
}

.group-members-section,
.recent-assessments-section {
    flex: 1 1 50%;
    padding: 15px;
}

.recent-assessments-section {
    flex: 1 1 40%;
}

.add-group-users {
    margin-top: 30px;
    max-width: 75%;
}

.add-group-users h3 {
    margin-bottom: 10px;
}

.recent-assessments-section {
    padding-top: 21px;
}

.recent-assessments-section table tbody td {
    color: var(--g500);
}

.recent-assessments-section table tbody tr {
    height: 40px;
}

.recent-assessments-section table tbody td {
    font-size: 12px;
}

.new-item-modal-content {
    position: relative;
    margin: 10% auto;
    border-radius: 7px;
    width: 96%;
    max-width: 600px;
    overflow-y: visible;
    background-color: var(--g100);
    padding: 20px 35px;
}

.new-item-modal-content>*:not(:last-child) {
    margin-bottom: 18px;
}

.new-item-modal-content button {
    margin-top: 20px;
}

.new-item-input-group {
    display: flex;
}

.new-item-input {
    width: 100%;
    font-size: 16px;
    margin-bottom: 20px;
}

.input-padding {
    padding: 16px;
}

.button-large {
    padding: 12px 16px;
    font-size: 14px;
    font-weight: var(--bold);
}

.modal-button-container {
    display: flex;
    justify-content: space-between;
}

.button-left {
    margin-left: 0;
}

.entity-details-container,
.user-details-container {
    width: 75%;
    margin-top: 40px;
}

.entity-form,
.user-form {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;
}

.copy-user-scores {
    margin-top: 20px;
}

.notification-row {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 16px;
    width: 100%;
    max-width: 800px;
}

.notification-input {
    flex: 3;
    padding: 8px 12px;
    border: 1px solid var(--g300);
    border-radius: 4px;
}

input[type="email"].notification-input,
input[type="text"].notification-input {
    height: 38px;
}

label.notification-input {
    padding: 0;
    border: none;
    display: flex;
    align-items: center;
    gap: 8px;
}

.notification-input input[type="checkbox"] {
    margin-right: 6px;
}

.notification-select, 
.settings-select {
    flex: 2;
    min-width: 160px;
    padding: 8px 12px;
    border: 1px solid var(--g300);
    border-radius: 4px;
    background-color: var(--white);
    height: 38px;
}

.notification-input:focus,
.notification-select:focus,
.settings-select:focus {
    outline: none;
    border-color: var(--p500);
    box-shadow: 0 0 0 2px rgba(var(--p500-rgb), 0.2);
}

.settings-select {
    max-width: 400px;
    width: 100%;
}

.notification-row .button {
    flex-shrink: 0;
    height: 38px;
}

.add-recipient-row {
    margin-top: 20px;
}

.notification-actions {
    margin-top: 24px;
    padding-top: 16px;
    border-top: 1px solid var(--g200);
    display: flex;
    gap: 10px;
}

.notification-actions button {
    margin-right: 0;
    min-width: 120px;
}

.button-disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.pagination {
    justify-content: flex-end;
    margin-top: 15px;
    display: flex;
    gap: 15px;
}

.loading-indicator {
    height: 400px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

/*@media (max-width: 768px) {*/
/*    .content-wrapper table, .tab-content table {*/
/*        width: calc(100% + 1rem);*/
/*        margin-left: -.5rem;*/
/*        margin-right: -.5rem;*/
/*    }*/

/*    .group-members-section, .recent-assessments-section {*/
/*        flex: 1 1 100%;*/
/*    }*/

/*    .add-group-users {*/
/*        max-width: 100%;*/
/*    }*/

/*    .entity-details-container, .user-details-container {*/
/*        width: 100%;*/
/*    }*/
/*}*/