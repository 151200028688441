input, textarea, select {
    padding: 7px;
    border-radius: 7px;
    border: 1px solid var(--p500);
    color: var(--p500);
    font-family: var(--headings);
    font-size: 14px;
    background-color: var(--white);
}

/* Global checkbox styles */
input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 18px;
    height: 18px;
    border: 1px solid var(--p700);
    border-radius: 2px;
    background-color: var(--white);
    display: inline-block;
    position: relative;
    cursor: pointer;
    outline: none;
    transition: background-color 0.3s, border-color 0.3s;
}

input[type="checkbox"]:checked {
    background-color: var(--p500);
    border-color: var(--p500);
}

input[type="checkbox"]:checked::after {
    content: '✓';
    color: var(--white);
    font-size: 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

input[type="checkbox"]:focus, input[type="checkbox"]:hover {
    box-shadow: var(--purple-highlight-sm);
}

label {
    display: flex;
}

/* Global radio styles */
input[type="radio"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 18px;
    height: 18px;
    border: 1px solid var(--p700);
    border-radius: 50%;
    background-color: var(--white);
    display: inline-block;
    position: relative;
    cursor: pointer;
    outline: none;
    transition: background-color 0.3s, border-color 0.3s;
    margin-right: 10px;
}

input[type="radio"]:checked {
    border-color: var(--p500);
}

input[type="radio"]:checked::after {
    content: '';
    width: 10px;
    height: 10px;
    background-color: var(--p500);
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

input[type="radio"]:focus, input[type="radio"]:hover {
    box-shadow: var(--purple-highlight-sm);
}

/* Global disabled styles */
input:disabled,
textarea:disabled,
select:disabled,
input[type="checkbox"]:disabled,
input[type="radio"]:disabled {
    background-color: var(--g100);
    border-color: var(--g500);
    color: var(--g700);
    cursor: not-allowed;
}

input[type="checkbox"]:disabled:checked::after,
input[type="radio"]:disabled:checked::after {
    opacity: 0.7;
}

label:has(input:disabled) {
    color: var(--g700);
    cursor: not-allowed;
}

/* Disabled checkbox styles */
input[type="checkbox"]:disabled:checked {
    background-color: var(--g300);
}

input[type="checkbox"]:disabled:checked::after {
    color: var(--primary);
}

/* Form Groups */
.form-group {
    display: grid;
    grid-template-columns: 140px 1fr;
    align-items: center;
    gap: 1rem;
}

.form-group label {
    color: var(--g700);
    font-weight: var(--normal);
}

input.form-input, textarea.form-input, select.form-input {
    background-color: var(--white);
    border-color: var(--g500);
}

.form-input {
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    font-family: 'Inter', sans-serif;
    font-weight: var(--normal);
    color: var(--black);
}

.form-textarea {
    resize: vertical;
    min-height: 100px;
    font-family: 'Inter', sans-serif;
}

.form-input:focus, .form-textarea:focus, select.form-input:focus {
    outline: none;
    border: 1px solid var(--white);
    box-shadow: var(--purple-highlight-sm);
}

.admin-user-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.admin-user-form label {
    color: var(--g700);
    font-weight: var(--normal);
}

.admin-emails-input {
    font-size: 0.8rem;
    min-height: 100px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    resize: vertical;
}

.email-input-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 2px;
    border-radius: 7px;
    border: 1px solid var(--p500);
    cursor: text;
    background-color: var(--white);
}

.email-chip {
    background-color: var(--p100);
    border-radius: 16px;
    padding: 2px 8px;
    margin: 2px;
    font-size: 13px;
    user-select: none;
}

.remove-chip {
    background: none;
    border: none;
    color: var(--g500);
    cursor: pointer;
    font-size: 14px;
    margin-left: 4px;
    padding: 0 2px;
}

.email-input {
    flex-grow: 1;
    border: none;
    outline: none;
    font-size: 14px;
    padding: 4px;
    min-width: 50px;
}

.email-input-container:focus-within {
    box-shadow: var(--purple-highlight-sm);
}