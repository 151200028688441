.aria-chat-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 100;
}

.aria-chat-button {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: var(--p500);
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;
}

.aria-chat-button:hover {
  background: var(--p700);
}

.aria-chat-popup {
  position: absolute;
  bottom: 60px;
  right: 0;
  width: 350px;
  background: var(--white);
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  padding: 16px;
}

.tagged-people {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 16px;
}

.person-chip {
  background: var(--g100);
  border-radius: 16px;
  padding: 4px 12px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 6px;
}

.profile-tag {
  background: var(--p100);
  color: var(--p700);
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 12px;
}

.prompt-suggestions {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.prompt-button {
  background: var(--white);
  border: 1px solid var(--p500);
  color: var(--p500);
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s;
}

.prompt-button:hover {
  background: var(--p50);
}

.aria-chat-button svg {
  color: var(--white);
  width: 100%;
  height: 100%;
  max-width: 30px;
  max-height: 30px;
  min-width: 24px;
  min-height: 24px;
}

.aria-chat-close {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 24px;
  height: 24px;
  background: transparent;
  border: none;
  border-radius: 50%;
  color: var(--g500);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s;
}

.aria-chat-close:hover {
  color: var(--p700);
}

/* Add to src/styles/AriaChat.css */
@media screen and (max-width: 768px) {
    .aria-chat-container {
      bottom: 56px;
      right: 10px;
    }
  
    .aria-chat-button {
      width: 34px;
      height: 34px;
    }

    .aria-chat-popup {
      bottom: 50px;
      width: calc(100vw - 24px);
      right: -12px;
      max-width: 300px;
    }
  }