.guided-setup-modal {
    z-index: 1500;
    background-color: rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
}

.guided-setup-content {
    width: 95%;
    max-width: 900px;
    padding: 2.5rem;
    background-color: var(--g100);
    border-radius: 7px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    overflow-y: auto;
    max-height: 90vh;
}

.setup-modal-close-icon {
    background-color: transparent;
}

.setup-modal-close-icon svg {
    fill: var(--p100);
}

.guided-setup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--g200);
}

.guided-setup-header h2 {
    margin: 0;
}

.setup-step-indicator {
    text-align: right;
    padding: 0.5rem 1rem;
    background: var(--white);
    border-radius: 7px;
}

.setup-step {
    margin-bottom: 3rem;
}

.setup-step:last-child {
    margin-bottom: 0;
}

.setup-step h3 {
    margin-bottom: 1rem;
}

.setup-step p {
    margin-bottom: 1rem;
}

.email-fields {
    margin: 1.5rem 0;
}

.email-field {
    display: flex;
    gap: 1rem;
    margin-bottom: 0.75rem;
    align-items: center;
}

.email-field input {
    max-width: 400px;
}

.step-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.step-actions-left {
    flex: 0 0 auto;
}

.step-actions-right {
    display: flex;
    gap: 10px;
}

.final-actions {
    display: flex;
    gap: 1rem;
    margin: 1.5rem 0;
}

.final-actions button {
    flex: 1;
}

.note {
    background: var(--white);
    padding: 1.5rem;
    border-radius: 7px;
    margin: 1.5rem 0;
    border-left: 4px solid var(--p500);
    color: var(--g700);
    font-size: 0.9rem;
    line-height: 1.5;
}

.add-email {
    color: var(--p500);
    font-weight: var(--semi-bold);
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    margin-top: 0.5rem;
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
}

.add-email:hover {
    color: var(--p600);
}

.remove-email {
    color: var(--error);
    background: none;
    border: none;
    cursor: pointer;
    padding: 0.5rem 1rem;
    border-radius: 7px;
}

.remove-email:hover {
    background-color: var(--red);
}

.guided-setup-modal button {
    cursor: pointer;
    padding: 9px 18px;
    border: 0;
    border-radius: 17px;
    font-family: var(--text);
    font-weight: var(--semi-bold);
    font-size: 0.8rem;
    white-space: nowrap;
    box-shadow: var(--shadow-input);
    display: inline-flex;
    align-items: center;
}

.guided-setup-modal button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
}

/* .selected-contributors {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 1rem;
    min-height: 32px;
} */

.setup-instructions {
    background: var(--white);
    padding: 1.25rem;
    border-radius: 7px;
    margin-bottom: 1.5rem;
}

.instruction-title {
    font-weight: var(--semi-bold);
    color: var(--g900);
    margin-bottom: 0.75rem;
}

.setup-instructions ul {
    margin: 0;
    padding-left: 1.5rem;
}

.setup-instructions li {
    margin-bottom: 0.5rem;
    color: var(--g700);
}

.setup-instructions li:last-child {
    margin-bottom: 0;
}

.instruction-note {
    margin-top: 0.75rem;
    color: var(--g700);
    font-style: italic;
}

.input-help-text {
    color: var(--g600);
    font-size: 0.875rem;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
}
