.tags-list,
.add-tag {
    max-width: 500px;
    margin: 1rem 0;
}

.tag-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    border: 1px solid var(--g200);
    border-radius: 4px;
}

.add-tag {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
}

/* Settings Layout Styles */
.settings-section {
    margin-bottom: 40px;
    padding: 20px;
    background-color: var(--white);
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.heading-top-margin {
    margin-top: 30px;
    border-top: 1px solid var(--g200);
    padding-top: 30px;
}

.settings-section h3 {
    margin-top: 0;
    margin-bottom: 16px;
    color: var(--p700);
    font-size: 1.25rem;
}

.settings-section h4 {
    margin-top: 12px;
    margin-bottom: 12px;
    color: var(--g700);
    font-size: 1.1rem;
}

.sub-section {
    margin-top: 24px;
    padding-top: 16px;
    border-top: 1px solid var(--g200);
}

.sub-section:first-child {
    margin-top: 0;
    padding-top: 0;
    border-top: none;
}

.settings-row {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    margin-top: 16px;
}

.settings-column {
    flex: 1 1 calc(50% - 12px);
    min-width: 300px;
}

/* Section actions */
.section-actions {
    margin-top: 24px;
    padding-top: 16px;
    border-top: 1px solid var(--g200);
    display: flex;
    gap: 10px;
}

.section-actions button {
    min-width: 120px;
}

/* Position Configuration Section */
.position-config-section {
    margin-top: 30px;
    padding-top: 30px;
}

.position-container {
    margin-top: 10px;
}

.current-status {
    margin-bottom: 16px;
    padding: 12px 16px;
    background-color: var(--g100);
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.status-label {
    color: var(--g500);
    font-size: 0.875rem;
}

.status-value {
    color: var(--g700);
    font-weight: var(--semi-bold);
}

.role-description {
    margin: 12px 0;
    padding: 12px;
    background-color: var(--s50);
    border-radius: 6px;
    font-size: 14px;
    color: var(--g700);
    line-height: 1.5;
}

.role-category-actions {
    display: flex;
    gap: 12px;
    margin-top: 16px;
}

.notification-actions {
    margin-bottom: 20px;
}

/* Position Status containment and alignment */
.position-status-wrapper {
    /* Ensure the position-type-section is properly contained */
    display: flex;
    flex-direction: column;
}

/* Remove the previous alignment attempt */
.settings-column > .position-type-section {
    margin-top: 0;
}

/* Responsive layout */
@media (max-width: 768px) {
    .settings-column {
        flex: 1 1 100%;
    }
}

