@import url('./colors.css');
@import url('./fonts.css');

.login-container {
    background-color: var(--g100);
    color: var(--black);
    font-family: var(--text);
    font-weight: var(--normal);
    min-height: 100vh;
    padding-bottom: 100px;
}

.login-top-bar-container {
    background-color: var(--p500);
}

.login-top-bar {
    width: 100%;
    /*background-color: #F4F4F4;*/
    padding: 30px 0;
    text-align: center;
    /*margin-bottom: 20px; !* Increase space between top bar and next div *!*/
}

.login-logo {
    height: 90px;
}

.login-main-content {
    text-align: center;
    padding: 30px 20px;
    /*margin-bottom: 20px; !* Increase space between main content and next div *!*/
    background-color: var(--g100);
}

.login-h2 {
    font-family: var(--headings);
    /*margin-bottom: 40px;*/
    font-size: 2.2rem;
}

.login-subtitle {
    margin: 40px 10% 0 10%;
    font-size: 1.2rem;
}

.login-accent-purple {
    color: var(--p500);
}

.login-user-info-form {
    padding: 0 25%;
    text-align: left;
    /*margin-bottom: 20px; !* Increase space between form and next div *!*/
}

.login-input-group {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin: 10px 0; /* Increase space between input groups */
}

.login-input-group input,
.login-input-div input,
.login-input-email,
.login-input-password {
    padding: 12px; /* Increased padding for taller inputs */
    border-radius: 7px; /* Border radius */
    border: none; /* Remove border */
    color: var(--p500); /* Text color */
    font-family: var(--text); /* Font family */
    font-size: 16px;
    background-color: var(--white); /* Background color */
    box-shadow: var(--shadow-input);
}

.login-input-div {
    margin-top: 20px;
}

.login-label {
    display: block;
    font-weight: var(--thin);
    margin-bottom: 5px;
    font-size: 14px;
    color: var(--g500)
}

.login-input-email, .login-input-pass {
    width: 100%;
}

/* Focused state */
.login-input-email.focused,
.login-input-pass.focused {
    /*background-color: var(--g100);*/
    color: var(--p500);
    outline: none;
    box-shadow: var(--purple-highlight);
}

.login-submit-button {
    background-color: var(--p500);
    font-family: var(--text);
    font-weight: 600;
    font-size: 16px;
    color: #FFFFFF;
    padding: 12px;
    border: none;
    cursor: pointer;
    margin-top: 2rem;
    border-radius: 7px;
    width: 100%;
    text-transform: uppercase;
}

.invalid-feedback{
    font-size: 14px;
    padding: 3px;
    color: var(--red);
    margin: 0;
}

.forgot-password {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    color: var(--g300);
    font-weight: var(--thin);
}

.login-error{
    color: var(--red);
}

@media (max-width: 768px) {
    .login-top-bar,
    .login-main-content,
    .login-subtitle {
        padding: 20px 10px 20px 10px; /* Decrease padding for smaller screens */
        margin: 0 0; /* Decrease margin for smaller screens */
    }

    .login-logo {
        height: 60px;
    }

    .login-accent-orange {
        padding: 0px 0px;
        margin: 0px;
    }

    .login-h2 {
        font-size: 2.4rem; /* Decrease font size for smaller screens */
        margin-bottom: 0px; /* Decrease margin for smaller screens */
    }

    .login-subtitle {
        font-size: 1.2rem;
        margin: 10px;
    }
    .login-user-info-form {
        padding: 0 5%;
    }
}