.header-button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.header-button-container h2 {
    margin: 0; /* Remove default margin from h2 */
}
.contributor-insights-table .button-container {
    display: unset;
}
.contributor-insights-table .button-container .button-primary,
.contributor-insights-table .button-container .button-save {
    margin-right: 10px;
}

.position-toggle-container {
    display: inline-flex;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.position-toggle-button {
    font-family: var(--text);
    background-color: var(--g200);
    border: none;
    color: var(--g500);
    padding: 8px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 13px;
    margin: 0;
    cursor: pointer;
    transition: 0.3s;
    border-radius: 0;
}

.position-toggle-button:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.position-toggle-button:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.position-toggle-button:not(.active) {
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

.position-toggle-button.active {
    background-color: var(--p500);
    color: white;
    box-shadow: none;
}

input.target-name-input, input.group-name-input {
    width: 60%;
}

/* Target Survey */
.target-survey-container {
    padding: 0 5% 60px 5%;
}

.target-survey-container h3 {
    margin-bottom: 20px;
    font-size: 1.5rem;
}

.likert-container {
    margin-bottom: 30px;
}

.likert-container p {
    font-weight: var(--semi-bold);
    font-size: 1.2rem;
    color: var(--primary);
}

.likert-scale {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    max-width: 600px;
    margin-top: 10px;
}

.likert-card {
    flex: 1;
    margin: 0 5px;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
}

.likert-card input[type="radio"] {
    display: none;
}

.likert-card:hover {
    background-color: var(--g150);
}

.likert-scale-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border: 1px solid var(--g300);
    border-radius: 5px;
    transition: all 0.3s ease;
    height: 150px;
}

.target-survey-icons {
    font-size: 24px;
    color: var(--g500);
    margin-bottom: 10px;
}

.likert-card input[type="radio"]:checked + .likert-scale-label {
    background-color: var(--primary);
}

.likert-card input[type="radio"]:checked + .likert-scale-label,
.likert-card input[type="radio"]:checked + .likert-scale-label .target-survey-icons,
.likert-card input[type="radio"]:checked + .likert-scale-label .likert-text {
    color: var(--white);
}

.likert-text {
    font-size: 1em;
    text-align: center;
}

.likert-scale-label,
.likert-scale-label .material-icons,
.likert-scale-label .likert-text {
    transition: all 0.3s ease;
}

.survey-navigation {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    max-width: 600px;
}

.progress-bar {
    width: 100%;
    height: 10px;
    background-color: var(--white);
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 20px;
}

.progress {
    height: 100%;
    background-color: var(--primary);
    border-radius: 5px;
    transition: width 0.3s ease;
}

.step-indicator {
    text-align: center;
    font-weight: bold;
    margin-bottom: 5px;
}

.survey-nav-button {
    padding: 12px 34px;
    font-size: 1.1rem;
    border-radius: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nav-icon {
    width: 24px;
    height: 24px;
}

.survey-next-button {
    margin-left: auto;
}

.survey-nav-button .nav-icon:first-child {
    margin-right: 8px;
}

.survey-nav-button .nav-icon:last-child {
    margin-left: 8px;
}

/* Target Overlay */
.target-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.95);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 6%;
    text-align: center;
    font-size: 15px;
    z-index: 1;
}

.target-overlay h3 {
    margin-bottom: 1em;
}

.target-overlay p {
    margin-bottom: 0.5em;
}

.contributor-type-chip {
    background: var(--p300);
    color: var(--white);
    padding: 4px 8px;
    border-radius: 16px;
    font-size: 11px;
    display: inline-flex;
    align-items: center;
    gap: 5px;
    position: relative;
    font-weight: var(--normal);
    margin-left: 8px;
}

.contributor-type-benchmark {
    background: var(--g500);
}

.contributor-type-standard {
    background: var(--p500);
}

/* Position Status */
.position-type-section {
    margin-bottom: 24px;
}

.position-type-section h4 {
    margin: 0 0 16px 0;
    font-size: 1.1rem;
    color: var(--g700);
}

.current-status {
    margin-bottom: 24px;
    padding: 12px 16px;
    background-color: var(--g100);
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.status-label {
    color: var(--g500);
    font-size: 0.875rem;
}

.status-value {
    color: var(--g700);
    font-weight: var(--semi-bold);
}

.conversion-info {
    color: var(--g600);
    font-size: 0.875rem;
    line-height: 1.5;
    margin: 0;
}

/* Position action buttons */
.position-action-button {
    width: auto;
    min-width: 180px;
    display: inline-flex;
    justify-content: center;
    margin-top: 8px;
}

/* Confirmation UI for position status */
.confirmation-actions {
    background-color: var(--g100);
    border-radius: 8px;
    padding: 16px;
    margin-top: 8px;
}

.confirmation-message {
    color: var(--g700);
    font-size: 0.9rem;
    line-height: 1.5;
    margin: 0 0 16px 0;
}

.confirmation-buttons {
    display: flex;
    gap: 8px;
    justify-content: flex-end;
}

/* Position Status Chip */
.position-type-chip {
    display: inline-flex;
    align-items: center;
    padding: 4px 8px;
    border-radius: 16px;
    font-size: 0.75rem;
    font-weight: var(--medium);
}

.position-type-chip.active {
    background-color: var(--p100);
    color: var(--p700);
}

.position-type-chip.internal {
    background-color: var(--g100);
    color: var(--g700);
}

.user-assessment {
    background-color: var(--g100);
    color: var(--black);
    font-family: var(--text);
    min-height: 100vh;
}

.user-assessment-no-token {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    text-align: center;
}

.no-token-message {
    max-width: 400px;
    padding: 20px;
    background-color: var(--p300);
    color: var(--white);
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Role Category Selector Styles */
.role-category-selector {
    max-width: 700px;
    padding: 20px;
    background-color: var(--white);
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.role-category-selector h3 {
    margin-top: 0;
    color: var(--p700);
    font-size: 1.5rem;
}

.role-category-selector .form-group {
    width: 100%;
}

.role-category-modal .form-group {
    width: 100%;
    grid-template-columns: auto;
}

.role-category-selector label {
    display: block;
    margin-bottom: 8px;
    font-weight: 600;
    color: var(--p700);
}

.role-category-selector select {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    border-radius: 6px;
    border: 1px solid var(--p300);
    background-color: var(--white);
    margin-bottom: 10px;
}

.role-category-modal-select {
    width: 60%;
    padding: 12px;
    font-size: 16px;
    border-radius: 6px;
    border: 1px solid var(--p300);
    background-color: var(--white);
    margin-bottom: 10px;
    margin: 16px auto 0 auto;
}

.role-category-selector select:focus {
    outline: none;
    border-color: var(--p500);
    box-shadow: 0 0 0 2px rgba(var(--p500-rgb), 0.2);
}

.role-description {
    margin-top: 12px;
    margin-bottom: 20px;
    padding: 15px;
    background-color: var(--s50);
    border-radius: 6px;
    font-size: 14px;
    line-height: 1.5;
    color: var(--p700);
    width: 100%;
    box-sizing: border-box;
}

.setup-instructions ul {
    font-size: 0.9rem;
    color: var(--g600);
    margin-top: 8px;
    margin-bottom: 16px;
}

.setup-instructions .instruction-title {
    font-weight: var(--semi-bold);
    color: var(--p700);
    margin-bottom: 8px;
}

.setup-instructions li {
    margin-bottom: 4px;
}

.setup-instructions .category-name {
    font-weight: var(--semi-bold);
    color: var(--p700);
}

/* Role Category Settings Styles */
.role-category-section {
    margin-bottom: 32px;
    padding: 24px;
    background-color: var(--surface-1);
    border-radius: 8px;
}

.role-category-section .help-text {
    margin-bottom: 16px;
    color: var(--text-2);
    font-size: 14px;
    line-height: 1.5;
}

.role-category-section .help-text strong {
    color: var(--text-1);
}

.role-category-selector {
    max-width: 600px;
}

.role-category-display {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.role-category-value {
    font-size: 16px;
    font-weight: 500;
    color: var(--text-1);
    padding: 12px;
    background-color: var(--surface-2);
    border-radius: 6px;
    border: 1px solid var(--border-1);
}

.role-description {
    margin-top: 12px;
    padding: 16px;
    background-color: var(--surface-2);
    border-radius: 6px;
    font-size: 14px;
    line-height: 1.5;
    color: var(--text-2);
}

.role-category-actions {
    display: flex;
    gap: 12px;
    margin-top: 16px;
}

.role-category-actions .button {
    min-width: 120px;
}


/* Role Category Banner */
.role-category-banner {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    background-color: var(--w100);
    border-left: 4px solid var(--w500);
    margin-bottom: 16px;
    border-radius: 0 4px 4px 0;
    position: fixed;
    top: 10px;
    left: 10%;
    right: 10%;
    z-index: 1000;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.role-category-banner .banner-content {
    display: flex;
    align-items: center;
    margin-left: 12px;
    flex: 1;
}

.role-category-banner .button-text {
    margin-left: 16px;
    color: var(--p500);
    font-weight: var(--semi-bold);
    padding: 6px 12px;
}

.role-category-banner .banner-close {
    background: none;
    border: none;
    color: var(--g500);
    cursor: pointer;
    padding: 4px;
    margin-left: 8px;
}

.role-category-banner .banner-close:hover {
    color: var(--g700);
}

/* Highlight animation for role category section */
@keyframes highlight-pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(249, 177, 21, 0.4);
    }
    70% {
        box-shadow: 0 0 0 10px rgba(249, 177, 21, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(249, 177, 21, 0);
    }
}

.highlight-section {
    animation: highlight-pulse 1.5s ease-out;
    background-color: var(--w100) !important;
    transition: background-color 1.5s ease;
}
