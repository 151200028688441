.assessment-details-wrapper {
    position: relative;
    width: 100%;
    padding: 24px;
}

.layout-header {
    position: relative;
}

.assessment-details-content {
    margin-top: 48px; /* Space for the export dropdown */
}

.dropdown-button-icon {
    width: 16px;
    height: 16px;
    color: inherit;
    fill: currentColor;
}

.dropdown-button-text {
    margin-left: 6px;
}

.dropdown-button-menu {
    position: absolute;
    top: 100%;
    right: 0;
    margin-top: 8px;
    z-index: 1000;
    min-width: 200px;
    max-width: 300px;
    background-color: var(--white);
    border: 1px solid var(--g300);
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

/* Ensure wrappers with only empty children don't interfere with clicks */
.content-wrapper:only-child,
.assessment-details-wrapper:only-child,
.assessment-details-content:empty,
.content-wrapper > :only-child:empty,
.assessment-details-wrapper > :only-child:empty {
    pointer-events: none;
}

.dropdown-button-menu .dropdown-button-item {
    padding: 0.75rem 1rem;
    cursor: pointer;
    color: var(--g700);
    font-size: 14px;
    white-space: nowrap;
}

.dropdown-button-menu .dropdown-button-item:hover {
    background-color: var(--g100);
}

.dropdown-button-menu .dropdown-button-item.disabled {
    cursor: not-allowed;
    opacity: 0.7;
}

.linked-positions-container .dropdown-button-menu {
    right: auto;
}

.export-button {
    background-color: var(--p300);
    padding: 8px 14px;
    display: flex;
    align-items: center;
    color: var(--white);
}

.export-button:hover {
    background-color: var(--p700);
}

.export-button .dropdown-button-icon {
    width: 16px;
    height: 16px;
    color: var(--white);
    fill: var(--white);
}

/* Handle table cell positioning */
td .dropdown-button-menu {
    position: fixed;  /* Use fixed positioning in tables */
    left: auto;      /* Reset left alignment */
    margin-top: 0;
}

@media (max-width: 768px) {
    .export-button-wrapper .dropdown-button-text {
        display: none;
    }

    .export-button {
        padding: 7px 7px;
        border-radius: 4px;
    }
    
    .dropdown-button-icon {
        width: 13px;
        height: 13px;
    }
}

@media (min-width: 768px) {
    .dropdown-button-menu {
        /* right: 0; */
        transform: translateX(0);
        @supports (width: fit-content) {
            width: fit-content;
            min-width: 200px;
        }
    }

    .dropdown-button-container:has(.dropdown-button-menu) {
        position: relative;
    }
} 

.dropdown-button-item {
    display: flex;
    align-items: center;
    gap: 8px;
}

.dropdown-item-icon {
    width: 16px;
    height: 16px;
    color: inherit;
}